/* General Utilities */
.align-left {
  text-align: left !important;
}

.full-width {
  width: 100% !important;
}

.div-height-hundred-vh {
  min-height: 100vh !important;
}

.min-height-30vh {
  min-height: 30vh !important;
}

.min-height-40vh {
  min-height: 40vh !important;
}

.min-height-50vh {
  min-height: 50vh !important;
}

.min-height-60vh {
  min-height: 60vh !important;
}

.buttom-border {
  border-bottom: 1px solid #e0e0e0 !important;
}

/* Input Styles */
.custom-input {
  border: 1px solid #6e6e6e !important;
  border-radius: 4px 0 0 4px !important;
  height: 40px !important;
  max-width: 400px !important;
  width: 100%;
  font-size: 0.9em !important;
}

.custom-input::placeholder {
  color: #3b3b3b !important;
}

@media (max-width: 576px) {
  .custom-input {
    /* margin-top: 1rem !important; */
    margin-bottom: 1rem !important;
    max-width: 80% !important; /* Limit the width to 80% of the container */
    width: auto !important;   /* Allow it to shrink if needed */
  }
}

@media (max-width: 1200px) {
  .margin-small-screen {
    /* margin-top: 1rem !important; */
    margin-bottom: 1rem !important;
  }
}



/* Dropdown Styles */
.search-dropdown-navbar-button {
  border: 1px solid #6e6e6e !important;
  background-color: #ffffff !important;
  border-radius: 0 4px 4px 0 !important;
  height: 40px !important;
  font-size: 0.9em !important;
}

.search-dropdown-navbar-button:hover {
  background-color: #f0efef !important;
  color: #000000 !important;
}

.profile-dropdown-navbar-button {
  border: 1px solid #6e6e6e !important;
  background-color: #ffffff !important;
  border-radius: 4px !important;
  height: 40px !important;
  font-size: 0.9em !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.profile-dropdown-navbar-button:hover {
  background-color: #f0efef !important;
  color: #000000 !important;
}

.dropdown-toggle:hover {
  background-color: #f0efef !important;
  color: #000000 !important;
}

/* Specific Dropdown: Din Profil */
.profile-dropdown-button {
  border-radius: 4px !important; /* Add rounded corners */
  /* border: 1px solid #6e6e6e !important; */
  height: 40px !important;
  font-size: 0.9em !important;
  background-color: #ffffff !important;
}

.profile-dropdown-button:hover {
  background-color: #f0efef !important;
  color: #000000 !important;
}

/* Dropdown Menu and Items */
.dropdown-menu {
  border: 1px solid #6e6e6e !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  box-shadow: none !important;
}

.dropdown-menu .dropdown-item {
  color: black !important;
  font-size: 0.9em !important;
  padding: 10px 15px !important;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f0efef !important;
  color: #000000 !important;
}

/* Focus Styles */
.form-control:focus,
.form-select:focus {
  box-shadow: none !important;
  border-color: #6e6e6e !important;
  outline: none !important;
}

/* Font Size Utilities */
.zero-nine-em-font-size {
  font-size: 0.9em !important;
}

.one-em-font-size {
  font-size: 1em !important;
}

.one-point-one-em-font-size {
  font-size: 1.1em !important;
}

/* Responsive Width for Navigation Buttons */
.responsive-width-navbuttons {
  width: auto; /* Default width for larger screens */
  
}

/* Semi-small screens (max-width: 992px) */
@media (max-width: 1200px) {
  .responsive-width-navbuttons {
    width: auto;
    max-width: 130px !important;
  }
}

/* Very small screens (max-width: 576px) */
@media (max-width: 576px) {
  .responsive-width-navbuttons {
 
  }
}

/* @media (max-width: 576px) {
  .adimg-searchpage {
    margin-bottom: 20px !important;
   max-width: 90% !important;

  }
}
@media (min-width: 577px) {
  .adimg-searchpage {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
} */

.adimg-searchpage {
  width: 100% !important; /* Set the desired width */
  height: 150px; /* Set the desired height */
  object-fit: cover; /* Crop the image to fill the box */
  border-radius: 4px; /* Optional: Add rounded corners */
  overflow: hidden; /* Ensure no overflow outside the box */
  max-width: 150px !important;
}

@media (max-width: 576px) {
  .adimg-searchpage {
    margin-bottom: 20px !important;
    max-width: 150px !important; /* Ensure the image scales properly on small screens */
    height: auto !important; /* Maintain aspect ratio on small screens */
  }
}




@media (min-width: 577px) {
  .aditem-searchpage {
    margin-left: 6rem !important; /* Equivalent to Bootstrap's ps-5 */
    /* margin-right: 10rem !important;  */
  }
}


@media (max-width: 577px) {
  .aditem-searchpage {
    margin-left: 2.5rem !important; /* Equivalent to Bootstrap's ps-5 */
    margin-right: 2.5rem !important; /* Equivalent to Bootstrap's ps-5 */
  }
}


.font-weight-700 {
  font-weight: 700 !important;
}